import styled from "styled-components";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/styles";
import { ReactComponent as close } from "assets/close-x.svg";
import { SIZES } from "common/constants/sizes.contants";
import { COLORS } from "common/constants/color.contants";
import { IIconImage } from "./types";

export const CropContainerPlay = styled.div`
  width: 70%;
  height: 95%;
  background: white;
  border-radius: 8px;
  position: relative;
  padding: 0 60px;

  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    overflow-y: scroll;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 60%;
    padding: 0 55px;
  }
`;

export const CropTitle = styled.h2`
  margin-top: 40px;
  font: normal normal 400 25px/30px "Avenir-Black";
  letter-spacing: 0px;
  text-align: center;
  color: #425061;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 20px;
    margin-top: 25px;
  }
`;

export const UploadButton = styled.p`
  font: normal normal 14px "Avenir-Medium";
  color: #e98573;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 10px;
  }
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content space-between;
  padding: 25px 0;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape){
    padding: 14px 0;
  }
`;

export const Subtitle = styled.p`
  font: normal normal 14px/18px "Avenir-Roman";
  letter-spacing: 0px;
  color: #425061;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 10px;
  }
`;

export const CropContainer = styled.div`
  width: 100%;
  height: 40%;
  margin-bottom: 30px;
  position: relative;

  .reactEasyCrop_Container {
    border-radius: 10px;
  }
`;

export const ZoomContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
`;

export const CropperSlider = withStyles({
  root: {
    color: "#ebedef",
    margin: "0 20px",
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#e98573",
    marginTop: -4,
    marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

export const IconWrapper = styled.div`
  display: flex;
`;

export const IconImage = styled.div<IIconImage>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
  margin: auto;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 14%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonApply = styled.div`
  width: 180px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: normal normal 18px/27px "Avenir-Medium";
  background: #fc3c46;
  border-radius: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.vivid};
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 150px;
    height: 30px;
  }
`;

export const CloseButton = styled(close)`
  width: 43px;
  height: 43px;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  &:hover {
    g {
      circle {
        fill: #7d4127;
      }
    }
  }
`;
