import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { COMPLETE_NAME_REGEX, EMAIL_REGEX } from "helper/validations/contants";
import { INearpodCharacterCampaignFormData } from "common/interfaces/nearpod-character-campaign.interface";
import i18next from "i18next";
import "./i18n";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import bgAnim from "./bgAnim.json";
import IStep1Props from "./types";
import {
  TextInput,
  SelectDropDown,
  StateSelect,
  FormContainer,
  FormTitle,
  Button,
  ErrorText,
  PlaceholderOption,
  Option,
  StateErrorText,
  BigContainer,
} from "./styles";
import Countries from "../countries.json";
import UsaStates from "../usa_states.json";

const Step1: FunctionComponent<IStep1Props> = (props: IStep1Props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [hasNameError, setHasNameError] = useState<"required" | "regex" | "">(
    ""
  );
  const [hasEmailError, setHasEmailError] = useState<"required" | "regex" | "">(
    ""
  );
  const [hasInstitutionError, setHasInstitutionError] = useState<
    "required" | ""
  >("");
  const [hasCountryError, setHasCountryError] = useState<"required" | "">("");
  const [hasStateError, setHasStateError] = useState<"required" | "">("");
  const [isUS, setIsUS] = useState(false);

  const onChangeNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setHasNameError("");
    setName(event.target.value);
  };

  const onChangeEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    setHasEmailError("");
    setEmail(event.target.value);
  };

  const onChangeInstitutionInput = (event: ChangeEvent<HTMLInputElement>) => {
    setHasInstitutionError("");
    setInstitution(event.target.value);
  };

  const handleSubmit = () => {
    const nameCheck = () => {
      if (name === "") {
        setHasNameError("required");
        return false;
      }
      if (!COMPLETE_NAME_REGEX.test(name)) {
        setHasNameError("regex");
        return false;
      }
      setHasNameError("");
      return true;
    };

    const emailCheck = () => {
      if (email === "") {
        setHasEmailError("required");
        return false;
      }
      if (!EMAIL_REGEX.test(email)) {
        setHasEmailError("regex");
        return false;
      }
      setHasEmailError("");
      return true;
    };

    const institutionCheck = () => {
      if (institution === "") {
        setHasInstitutionError("required");
        return false;
      }
      setHasInstitutionError("");
      return true;
    };

    const countryCheck = () => {
      if (country === "") {
        setHasCountryError("required");
        return false;
      }
      setHasCountryError("");
      return true;
    };

    const stateCheck = () => {
      if (state === "") {
        setHasStateError("required");
        return false;
      }
      setHasStateError("");
      return true;
    };

    const nameRight = nameCheck();
    const emailRight = emailCheck();
    const institutionRight = institutionCheck();
    const countryRight = countryCheck();
    const stateRight = stateCheck();

    if (
      nameRight &&
      emailRight &&
      institutionRight &&
      countryRight &&
      (!isUS || stateRight)
    ) {
      const data: INearpodCharacterCampaignFormData = {
        name,
        email,
        country,
        state,
        institution,
      };
      props.onSubmitForm(data);
    }
  };

  const nameError = () => {
    return hasNameError === "required"
      ? i18next.t("ENGAGE_CHARACTER_STEP_ONE:NAME_REQUIRED")
      : i18next.t("ENGAGE_CHARACTER_STEP_ONE:NAME_REGEX");
  };

  const emailError = () => {
    return hasEmailError === "required"
      ? i18next.t("ENGAGE_CHARACTER_STEP_ONE:EMAIL_REQUIRED")
      : i18next.t("ENGAGE_CHARACTER_STEP_ONE:EMAIL_REGEX");
  };

  const institutionError = () => {
    return hasInstitutionError === "required"
      ? i18next.t("ENGAGE_CHARACTER_STEP_ONE:INSTITUTION_REQUIRED")
      : i18next.t("ENGAGE_CHARACTER_STEP_ONE:NONE");
  };

  const countryError = () => {
    return hasCountryError === "required"
      ? i18next.t("ENGAGE_CHARACTER_STEP_ONE:COUNTRY_REQUIRED")
      : i18next.t("ENGAGE_CHARACTER_STEP_ONE:NONE");
  };

  const stateError = () => {
    return hasStateError === "required"
      ? i18next.t("ENGAGE_CHARACTER_STEP_ONE:STATE_REQUIRED")
      : i18next.t("ENGAGE_CHARACTER_STEP_ONE:NONE");
  };

  const iconComponent = () => {
    return <ArrowDropDown style={{ fill: "red" }} />;
  };

  const onChangeSelectedCountry = (event: ChangeEvent<HTMLSelectElement>) => {
    setHasCountryError("");
    setCountry(event.target.value);
  };

  const onChangeSelectedState = (event: ChangeEvent<HTMLSelectElement>) => {
    setHasStateError("");
    setState(event.target.value);
  };

  useEffect(() => {
    setIsUS(country === "United States");
  }, [country]);

  return (
    <BigContainer>
      <FormContainer>
        <FormTitle>
          {i18next.t("ENGAGE_CHARACTER_STEP_ONE:FORM_TITLE")}
        </FormTitle>
        <TextInput
          type="text"
          placeholder="Name"
          id="name"
          onChange={onChangeNameInput}
          value={name}
          autoComplete="off"
          maxLength={26}
        />
        <ErrorText hasError={!!hasNameError}>{nameError()}</ErrorText>
        <TextInput
          type="email"
          placeholder="Email"
          id="email"
          onChange={onChangeEmailInput}
          value={email}
          autoComplete="off"
          maxLength={255}
        />
        <ErrorText hasError={!!hasEmailError}>{emailError()}</ErrorText>
        <TextInput
          type="text"
          placeholder="Institution"
          id="institution"
          onChange={onChangeInstitutionInput}
          value={institution}
          autoComplete="off"
          maxLength={26}
        />
        <ErrorText hasError={!!hasInstitutionError}>
          {institutionError()}
        </ErrorText>
        <SelectDropDown
          disableUnderline
          IconComponent={iconComponent}
          defaultValue="default"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: ChangeEvent<any>) => onChangeSelectedCountry(e)}
        >
          <PlaceholderOption value="default">
            {i18next.t("ENGAGE_CHARACTER_STEP_ONE:COUNTRY")}
          </PlaceholderOption>
          {Countries.map((item: { name: string; code: string }) => {
            return (
              <Option key={item.code} value={item.name}>
                {item.name}
              </Option>
            );
          })}
        </SelectDropDown>
        <ErrorText hasError={!!hasCountryError}>{countryError()}</ErrorText>
        <StateSelect
          isUS={isUS}
          disableUnderline
          IconComponent={iconComponent}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: ChangeEvent<any>) => onChangeSelectedState(e)}
          defaultValue="default"
        >
          <PlaceholderOption value="default">
            {i18next.t("ENGAGE_CHARACTER_STEP_ONE:STATE")}
          </PlaceholderOption>
          {UsaStates.map((item: { name: string; abbreviation: string }) => {
            return (
              <Option key={item.abbreviation} value={item.name}>
                {item.name}
              </Option>
            );
          })}
        </StateSelect>
        <StateErrorText hasError={!!hasStateError && isUS}>
          {stateError()}
        </StateErrorText>
        <Button onClick={handleSubmit}>
          {i18next.t("ENGAGE_CHARACTER_STEP_ONE:CONTINUE")}
        </Button>
      </FormContainer>
      <LottiePlayer
        animationJsonPath={bgAnim}
        autoplay
        loop
        segments={[24, 419]}
        preserveAspectRatio="xMidYMid meet"
      />
    </BigContainer>
  );
};

export default Step1;
