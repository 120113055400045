export const ON_CREATE_TEMPLATE_REQUESTED = "ON_CREATE_TEMPLATE_REQUESTED";
export const ON_CREATE_TEMPLATE_SUCCESS = "ON_CREATE_TEMPLATE_SUCCESS";
export const ON_CREATE_TEMPLATE_FAILED = "ON_CREATE_TEMPLATE_FAILED";
export const ON_INITIALIZE_TEMPLATE_REQUESTED =
  "ON_INITIALIZE_TEMPLATE_REQUESTED";
export const ON_INITIALIZE_TEMPLATE_SUCCEED = "ON_INITIALIZE_TEMPLATE_SUCCEED";
export const ON_INITIALIZE_TEMPLATE_FAILED = "ON_INITIALIZE_TEMPLATE_FAILED";
export const ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED =
  "ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED";
export const ON_INITIALIZE_TEMPLATE_DASHBOARD_SUCCEED =
  "ON_INITIALIZE_TEMPLATE_DASHBOARD_SUCCEED";
export const ON_INITIALIZE_TEMPLATE_DASHBOARD_FAILED =
  "ON_INITIALIZE_TEMPLATE_DASHBOARD_FAILED";
export const ON_SELECT_TEMPLATE_ADD = "ON_SELECT_TEMPLATE_ADD";
export const ON_INITIALIZE_TEMPLATE_FORM = "ON_INITIALIZE_TEMPLATE_FORM";
export const ON_DELETE_TEMPLATES_REQUESTED = "ON_DELETE_TEMPLATES_REQUESTED";
export const ON_DELETE_TEMPLATES_SUCCESS = "ON_DELETE_TEMPLATES_SUCCESS";
export const ON_DELETE_TEMPLATES_FAILED = "ON_DELETE_TEMPLATES_FAILED";
export const ON_RETRIEVE_TEMPLATE_COUNT_SUCCEED =
  "ON_RETRIEVE_TEMPLATE_COUNT_SUCCEED";
export const ON_RETRIEVE_TEMPLATE_COUNT_FAILED =
  "ON_RETRIEVE_TEMPLATE_COUNT_FAILED";
export const ON_RETRIEVE_TEMPLATES_REQUESTED =
  "ON_RETRIEVE_TEMPLATES_REQUESTED";
export const ON_RETRIEVE_TEMPLATES_SUCCEED = "ON_RETRIEVE_TEMPLATES_SUCCEED";
export const ON_RETRIEVE_TEMPLATES_FAILED = "ON_RETRIEVE_TEMPLATES_FAILED";
