import styled from "styled-components";
import { SIZES } from "common/constants/sizes.contants";
import { COLORS } from "common/constants/color.contants";
import { ISharePlayer, ISize } from "./types";

export const TemplateContainer = styled.div<ISharePlayer>`
  width: ${(props) => (props.isSharePlayer ? "100vw" : "100%")};
  height: ${(props) => (props.isSharePlayer ? "100vh" : "810px")};
  display: flex;
  flex-direction: column;
  background: ${COLORS.porcelain};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${SIZES.applePhone}px) {
    width: ${(props) => (props.isSharePlayer ? "100vh" : "100%")};
    height: ${(props) => (props.isSharePlayer ? "100vw" : "810px")};
  }
`;

export const VideoContainer = styled.div<ISize>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 375px) and (orientation: portrait) {
    width: 542.2222222222222px;
    height: 305;
  }
  @media screen and (max-width: 812px) and (orientation: landscape) {
    width: 488.88888888888886px;
    height: 275px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: ${SIZES.applePhone}px) {
    transform: rotate(90deg);
  }
`;

export const ControlsWrapper = styled.div<ISize>`
  width: ${(props) => props.width}px;
  height: 40px;

  @media screen and (max-width: 375px) and (orientation: portrait) {
    width: 542.2222222222222px;
    height: 305;
  }

  @media screen and (max-width: 812px) and (orientation: landscape) {
    width: 488.88888888888886px;
  }
`;
