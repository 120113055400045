export const ON_INITIALIZE_CAMPAIGN_FORM = "ON_INITIALIZE_CAMPAIGN_FORM";
export const ON_RETRIEVE_TEMPLATES_SUCCEED = "ON_RETRIEVE_TEMPLATES_SUCCEED";
export const ON_RETRIEVE_TEMPLATES_FAILED = "ON_RETRIEVE_TEMPLATES_FAILED";
export const ON_CREATE_CAMPAIGN_REQUESTED = "ON_CREATE_CAMPAIGN_REQUESTED";
export const ON_CREATE_CAMPAIGN_SUCCEED = "ON_CREATE_CAMPAIGN_SUCCEED";
export const ON_CREATE_CAMPAIGN_FAILED = "ON_CREATE_CAMPAIGN_FAILED";
export const ON_RETRIEVE_CAMPAIGNS_REQUESTED =
  "ON_RETRIEVE_CAMPAIGNS_REQUESTED";
export const ON_RETRIEVE_CAMPAIGNS_SUCCEED = "ON_RETRIEVE_CAMPAIGNS_SUCCEED";
export const ON_RETRIEVE_CAMPAIGNS_FAILED = "ON_RETRIEVE_CAMPAIGNS_FAILED";
export const ON_SELECT_CAMPAIGN_SUCCEED = "ON_SELECT_CAMPAIGN_SUCCEED";
export const ON_REDIRECT_TO_METRICS = "ON_REDIRECT_TO_METRICS";
export const ON_DELETE_CAMPAIGNS_REQUESTED = "ON_DELETE_CAMPAIGNS_REQUESTED";
export const ON_DELETE_CAMPAIGNS_SUCCEED = "ON_DELETE_CAMPAIGNS_SUCCEED";
export const ON_DELETE_CAMPAIGNS_FAILED = "ON_DELETE_CAMPAIGNS_FAILED";
export const ON_SELECT_CAMPAIGN_ADD = "ON_SELECT_CAMPAIGN_ADD";
export const ON_CREATE_SHARE_CAMPAIGN_REQUESTED =
  "ON_CREATE_SHARE_CAMPAIGN_REQUESTED";
export const ON_RETRIEVE_CAMPAIGN_COUNT_SUCCEED =
  "ON_RETRIEVE_CAMPAIGN_COUNT_SUCCEED";
export const ON_RETRIEVE_CAMPAIGN_COUNT_FAILED =
  "ON_RETRIEVE_CAMPAIGN_COUNT_FAILED";
export const ON_RETRIEVE_CAMPAIGN_REQUESTED = "ON_RETRIEVE_CAMPAIGN_REQUESTED";
