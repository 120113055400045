import { IAssetReducerState } from "redux/reducers/asset.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getAsset = (state: IStore) => state.asset;

const getAssetSelector = (): OutputSelector<
  IStore,
  IAssetReducerState,
  (res: IAssetReducerState) => IAssetReducerState
> => createSelector([getAsset], (asset) => asset);

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: IAssetReducerState) => number
> => createSelector([getAsset], (data) => data.count);

export default getAssetSelector;
