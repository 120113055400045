import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import React, { FunctionComponent, useState } from "react";
import bgAnim from "./bgAnim.json";
import YetiAnim from "./yestiStep4.json";
import {
  AvatarImage,
  Background,
  BadgeImage,
  YetiContainer,
  BadgeSection,
  AvatarSection,
  TitleWrapper,
  Subtitle,
  ImageTitle,
} from "./styles";
import { IProps } from "./types";

const Step4: FunctionComponent<IProps> = (props: IProps) => {
  const [showYeti, setShowYeti] = useState<boolean>(true);

  setTimeout(() => {
    if (showYeti) {
      setShowYeti(false);
    }
  }, 5500);

  return (
    <Background>
      {showYeti && (
        <YetiContainer>
          <LottiePlayer
            animationJsonPath={YetiAnim}
            autoplay
            loop={false}
            preserveAspectRatio="xMidYMid meet"
            name="Yeti Animation"
          />
        </YetiContainer>
      )}
      <LottiePlayer
        animationJsonPath={bgAnim}
        autoplay
        loop
        preserveAspectRatio="xMidYMid meet"
        name="Background Animation"
      />
      <TitleWrapper>
        <ImageTitle src="nearpodAvatarCampaign/download.svg" />
        <Subtitle>
          Press and hold your images to use and share on social media!
        </Subtitle>
      </TitleWrapper>
      <BadgeSection>
        <BadgeImage src={props.badgeImage} />
      </BadgeSection>
      <AvatarSection>
        <AvatarImage src={props.avatarImage} />
      </AvatarSection>
    </Background>
  );
};

export default Step4;
