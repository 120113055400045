import DashboardTable from "components/DashboardTable/DashboardTable";
import React, { FunctionComponent } from "react";
import { IProps } from "./types";

const DashboardView: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <DashboardTable
        data={props.data}
        keys={props.keys}
        title={props.title}
        isTemplateDashboard={props.isTemplate}
        onDelete={props.onDelete}
        isCampaignDashboard={props.isCampaign}
        selectCampaign={props.selectCampaign}
        onClickAdd={props.onClickAdd}
        onEdit={props.onEdit}
        onClickViewMetrics={props.onClickViewMetrics}
        createShareCampaign={props.createShareCampaign}
        page={props.actualPage}
        setPage={props.setPage}
        count={props.count}
        search={props.search}
      />
    </>
  );
};

export default DashboardView;
