import { AnyAction } from "redux";
import * as userConstants from "redux/constants/user.constants";
import * as appConstants from "redux/constants/app.constants";
import * as campaignConstants from "redux/constants/campaign.constants";
import * as shareConstants from "redux/constants/share.constants";
import * as templateConstants from "redux/constants/template.constants";
import * as clientContants from "redux/constants/client.constants";
import * as assetConstants from "redux/constants/asset.constants";
import * as textVariableConstants from "redux/constants/text-variable.constants";
import history from "helper/history";
import { getCookie } from "helper/cookies";

const redirectMiddleware =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) =>
  (action: AnyAction): AnyAction => {
    switch (action.type) {
      case userConstants.USER_ON_LOGIN_SUCCEEDED:
        history.push({
          pathname: "/dashboard",
        });
        break;
      case campaignConstants.ON_CREATE_SHARE_CAMPAIGN_REQUESTED:
        history.push(`/dashboard/share-campaign/create/${action.campaignId}`);
        break;
      case campaignConstants.ON_SELECT_CAMPAIGN_SUCCEED:
      case shareConstants.ON_ADD_SHARE_CAMPAIGN_SUCCEED:
      case shareConstants.ON_EDIT_SHARE_CAMPAIGN_SUCCEED:
        history.push({
          pathname: `/dashboard/share-campaigns/${action.campaignId}`,
        });
        break;
      case assetConstants.ON_SELECT_ADD_ASSET:
        history.push(`/dashboard/assets/add/`);
        break;
      case assetConstants.ON_SUBMIT_ASSET_SUCCEED:
        history.push(`/dashboard/assets/`);
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case userConstants.USER_ON_REGISTER_SUCCEEDED:
      case userConstants.ON_LOGOUT_SUCCEEDED:
      case userConstants.ON_CHANGE_PASSWORD_SUCCEEDED:
        history.push("/login");
        break;
      case shareConstants.ON_SELECT_SHARE_CAMPAIGN_ADD:
        history.push(`/dashboard/share-campaigns/add/${action.campaignId}`);
        break;
      case shareConstants.ON_SELECT_SHARE_CAMPAIGN_EDIT:
        history.push(
          `/dashboard/share-campaigns/edit/${action.shareCampaignId}`
        );
        break;
      case textVariableConstants.ON_SELECT_TEXT_VARIABLE_EDIT:
        history.push(`/dashboard/text-variable/edit/${action.textVariableId}`);
        break;
      case textVariableConstants.ON_EDIT_TEXT_VARIABLE_SUCCEED:
        history.push(`/dashboard/text-variables/`);
        break;
      case userConstants.ON_INITIALIZE_LOGIN_FAILED:
        history.push("/dashboard");
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
        if (action.restricted && action.user) {
          history.push({
            pathname: "/",
          });
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          history.go(0);
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED:
        if (!getCookie("accessToken")) {
          history.push("/login");
          break;
        }
        break;
      case userConstants.ON_REDIRECT_TO_CREATE_USER_FORM:
        history.push("/dashboard/users/create");
        break;
      case templateConstants.ON_SELECT_TEMPLATE_ADD:
        history.push("/dashboard/templates/create");
        break;
      case campaignConstants.ON_REDIRECT_TO_METRICS:
        history.push(`/dashboard/campaign/${action.campaignId}/metrics`);
        break;
      case clientContants.ON_SELECT_CLIENT_ADD:
        history.push("/dashboard/clients/create");
        break;
      case campaignConstants.ON_SELECT_CAMPAIGN_ADD:
        history.push("/dashboard/campaign/create");
        break;
      case campaignConstants.ON_CREATE_CAMPAIGN_SUCCEED:
        history.push("/dashboard/campaigns/");
        break;
      case clientContants.ON_SUBMIT_CLIENT_CREATE_SUCCESS:
        history.push("/dashboard/clients/");
        break;
      case shareConstants.ON_INITIALIZE_PLAYER_FAILED:
        history.push("/404");
        break;
      default:
        break;
    }
    return next(action);
  };

export default redirectMiddleware;
