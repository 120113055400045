import { takeLatest, call, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import retrieveTexts from "helper/retrievers/retrieveTextLayer.helper";
import retrieveImages from "helper/retrievers/retrieveImageLayer.helper";
import { ITemplate } from "common/interfaces/template.interface";
import { IDataLayer, Layer } from "common/interfaces/layer.interface";
import removeObjectNullProperties from "helper/removeObjectNullProps.helper";
import * as services from "service/template.service";
import { IAPIError } from "common/interfaces/api.interface";
import { IFiles } from "common/interfaces/file.interface";
import * as templateActions from "../actions/template.actions";
import * as campaignActions from "../actions/campaign.actions";
import * as constants from "../constants/template.constants";
import * as campaignConstants from "../constants/campaign.constants";

export interface ResponseGenerator {
  data: any;
}

export interface ResponseGeneratorLayers {
  data: any;
  text: string;
  image: string;
  find: (layer: any) => string;
}

function* getTemplate(action: AnyAction) {
  try {
    const response: ITemplate = yield call(
      services.getTemplateService,
      action.templateId
    );
    response.layers = yield call(services.getJsons, response.layers);
    yield put(templateActions.onInitializeTemplateSucceed(response));
  } catch (err) {
    yield put(templateActions.onInitializeTemplateFailed(err as IAPIError));
  }
}

function* getTemplates() {
  try {
    const response: ITemplate[] = yield call(services.getTemplatesService);
    yield put(campaignActions.onGetTemplatesSucceed(response));
  } catch (err) {
    yield put(campaignActions.onGetTemplatesFailed(err as IAPIError));
  }
}

function* getTenTemplates(action: AnyAction) {
  try {
    const response: ITemplate[] = yield call(
      services.getTenTemplatesService,
      action.query,
      action.page
    );
    yield put(templateActions.onGetTemplatesSucceed(response));
  } catch (err) {
    yield put(templateActions.onGetTemplatesFailed(err as IAPIError));
  }
}

function* getTemplateCount() {
  try {
    const count: number = yield call(services.getCount);
    yield put(templateActions.getTemplateCountSucceed(count));
  } catch (err) {
    yield put(templateActions.getTemplateCountFailed(err as IAPIError));
  }
}

function* createTemplate(action: AnyAction) {
  try {
    const response: ResponseGenerator = yield call(
      services.createTemplateService,
      action.template
    );
    const layers: ResponseGeneratorLayers = yield call(
      services.createLayers,
      removeObjectNullProperties(action.files) as IFiles,
      response.data
    );
    if (action.audio.assetAudio.length) {
      yield call(services.createAudio, action.audio, response.data);
    }
    if (action.video.length) {
      yield call(services.createVideo, action.video, response.data);
    }
    const result: any = layers.find((layer: any) => layer.key === "texts");
    const assetText: IDataLayer = yield call(
      services.getAssetPath,
      result.assetId
    );
    const textVariables = retrieveTexts(assetText);
    yield call(services.createTextVariables, textVariables, response.data);
    if (action.images.length) {
      const resultImages: any = layers.find(
        (layer: Layer) => layer.key === "images"
      );
      const assetImage: IDataLayer = yield call(
        services.getAssetPath,
        resultImages.assetId
      );

      const imageVariables = retrieveImages(
        assetImage,
        response.data,
        action.images
      );
      yield call(services.createImageVariables, imageVariables);
    }
    const responseTemplate: ITemplate = yield call(
      services.getTemplateService,
      response.data
    );
    yield put(templateActions.onCreateTemplateSuccess(responseTemplate));
  } catch (err) {
    yield put(templateActions.onCreateTemplateFailed());
  }
}

function* deleteTemplates(action: AnyAction) {
  try {
    const response: ITemplate[] = yield call(
      services.deleteTemplates,
      action.templatesIds
    );
    yield put(templateActions.onDeleteTemplatesSuccess(response));
  } catch (err) {
    yield put(templateActions.onDeleteTemplatesFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_CREATE_TEMPLATE_REQUESTED, createTemplate),
  takeLatest(constants.ON_INITIALIZE_TEMPLATE_REQUESTED, getTemplate),
  takeLatest(constants.ON_DELETE_TEMPLATES_REQUESTED, deleteTemplates),
  takeLatest(campaignConstants.ON_INITIALIZE_CAMPAIGN_FORM, getTemplates),
  takeLatest(
    constants.ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED,
    getTenTemplates
  ),
  takeLatest(
    constants.ON_INITIALIZE_TEMPLATE_DASHBOARD_REQUESTED,
    getTemplateCount
  ),
]);
