import styled from "styled-components";
import { COLORS } from "common/constants/color.contants";
import { MenuItem, Select } from "@material-ui/core";
import { SIZES } from "common/constants/sizes.contants";
import { IError, IStateSelectProps } from "./types";

export const BigContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4bd7f;
  position: relative;
  overflow: hidden;
`;

export const TextInput = styled.input`
  border: none;
  border-bottom: 1px solid ${COLORS.chocolate};
  margin: 20px 20px 5px 20px;
  color: ${COLORS.bronze};
  font-family: Avenir-Medium;
  &::placeholder {
    color: ${COLORS.bronze};
    opacity: 1;
  }
`;

export const FormContainer = styled.div`
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 35px;
  position: absolute;
  z-index: 10;
  top: 24%;
  left: 53%;

  @media screen and (max-height: 700px) {
    top: 14%;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    height: 40%;
    overflow-y: scroll;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 60%;
    overflow-y: scroll;
    left: 53%;
  }
`;

export const FormTitle = styled.h1`
  margin: 20px;
  color: ${COLORS.bronze};
  font-family: Avenir-Medium;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const SelectDropDown = styled(Select)`
  margin: 20px 20px 5px 20px;
  border-bottom: 1px solid ${COLORS.chocolate};
  background-color: ${COLORS.white};
  color: ${COLORS.bronze};
  .MuiSelect-select {
    color: ${COLORS.bronze};
    font-family: Avenir-Medium;
    &:focus {
      background-color: ${COLORS.white};
    }
  }
`;

export const StateSelect = styled(SelectDropDown)<IStateSelectProps>`
  visibility: ${(props) => (props.isUS ? "visible" : "hidden")};
`;

export const Option = styled(MenuItem)`
  color: ${COLORS.bronze}!important;
  font-family: Avenir-Medium !important;
`;

export const PlaceholderOption = styled(Option)`
  display: none !important;
`;

export const Button = styled.button`
  width: 200px;
  background-color: ${COLORS.redSalsa};
  color: ${COLORS.white};
  font-family: Avenir-Medium;
  border: none;
  padding: 10px 25px;
  border-radius: 20px;
  font-size: 18px;
  align-self: center;
  transition: all 0.2s ease-out;
  cursor: pointer;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  &:hover {
    background-color: ${COLORS.vivid};
  }
`;

export const ErrorText = styled.p<IError>`
  color: ${COLORS.red};
  font-family: Avenir-Medium;
  margin: 0 5px 10px 20px;
  font-size: 10px;
  visibility: ${(props) => (props.hasError ? "visible" : "hidden")};
`;

export const StateErrorText = styled(ErrorText)<IError>`
  margin: 0 5px 20px 20px;
`;
