import { IAPIError } from "common/interfaces/api.interface";
import { IAsset } from "common/interfaces/file.interface";
import { INearpodCharacterCampaign } from "common/interfaces/nearpod-character-campaign.interface";
import { AnyAction } from "redux";
import { IImageFile } from "views/TemplateForm/components/components/UploadImagesForm/types";
import * as constants from "../constants/asset.constants";

export const onUpload = (files: IImageFile[]): AnyAction => ({
  type: constants.ON_UPLOAD_REQUESTED,
  files,
});

export const onUploadSuccess = (images: IAsset[]): AnyAction => ({
  type: constants.ON_UPLOAD_SUCCESS,
  images,
});

export const onUploadFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_UPLOAD_FAILED,
  error,
});

export const getAssetPath = (path: string): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSET_PATH_REQUESTED,
  path,
});

export const getAssetPathSuccess = (path: string): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSET_PATH_SUCCESS,
  path,
});

export const getAssetPathFailed = (path: string): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSET_PATH_FAILED,
  path,
});

export const onInitializeAssetDashboard = (
  query: string,
  page: number
): AnyAction => ({
  type: constants.ON_INITIALIZE_ASSETS_DASHBOARD_REQUESTED,
  query,
  page,
});

export const onGetAssetsSucceed = (data: IAsset[]): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSETS_SUCCEED,
  data,
});

export const onGetAssetFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSETS_FAILED,
  error,
});

export const getAssetCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSET_COUNT_SUCCEED,
  count,
});

export const getAssetCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_ASSET_COUNT_FAILED,
  error,
});

export const onSelectAdd = (): AnyAction => ({
  type: constants.ON_SELECT_ADD_ASSET,
});

export const onAddAsset = (files: FileList): AnyAction => ({
  type: constants.ON_SUBMIT_ASSET_REQUESTED,
  files,
});

export const onAddCropAsset = (file: File): AnyAction => ({
  type: constants.ON_SUBMIT_CROP_ASSET_REQUESTED,
  file,
});

export const onAddCropAssetSucceed = (assetPath: string): AnyAction => ({
  type: constants.ON_SUBMIT_CROP_ASSET_SUCCEED,
  assetPath,
});

export const onExportAsset = (
  _id: string,
  file: File,
  isAvatar: boolean
): AnyAction => ({
  type: constants.ON_EXPORT_REQUESTED,
  _id,
  file,
  isAvatar,
});

export const onExportAssetSucceed = (
  data: INearpodCharacterCampaign
): AnyAction => ({
  type: constants.ON_EXPORT_SUCCEED,
  data,
});

export const onAddAssetSucceed = (): AnyAction => ({
  type: constants.ON_SUBMIT_ASSET_SUCCEED,
});

export const onAddAssetFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_SUBMIT_ASSET_FAILED,
  error,
});

export const onDeleteAsset = (assetsIds: string[]): AnyAction => ({
  type: constants.ON_DELETE_ASSET_REQUESTED,
  assetsIds,
});

export const onDeleteAssetSucceed = (data: IAsset[]): AnyAction => ({
  type: constants.ON_DELETE_ASSET_SUCCEED,
  data,
});

export const onDeleteAssetFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_ASSET_FAILED,
  error,
});
