import React, {
  useState,
  useCallback,
  FunctionComponent,
  useEffect,
  useRef,
} from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import getCroppedImg, {
  base64ToFile,
  toBase64,
} from "helper/imageCropper.helper";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import { PUBLIC_URL } from "configs/configs";
import {
  CropContainerPlay,
  CropContainer,
  ZoomContainer,
  CropTitle,
  SubtitleContainer,
  CropperSlider,
  IconWrapper,
  IconImage,
  Subtitle,
  ButtonContainer,
  UploadButton,
  ButtonApply,
  CloseButton,
} from "./styles";
import { IProps } from "./types";

const ImageCropper: FunctionComponent<IProps> = (props: IProps) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  // const [croppedImage, setCroppedImage] = useState<string>("");
  const [imageSelected, setImageSelected] = useState(props.defaultImage);
  const [inputFile, setInputFile] = useState<FileList>();

  const onCropComplete = useCallback(
    (croppedArea: Area, cropAreaPixels: Area) => {
      setCroppedAreaPixels(cropAreaPixels);
    },
    []
  );

  const onFileUpload = async (fileList: FileList) => {
    if (fileList.length) {
      const file = fileList[0];
      setImageSelected(await toBase64(file));
      setInputFile(fileList);
    }
  };

  const onHandleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length && files[0].size > 2097152) {
      props.setError(true);
    } else if (files?.length && files[0].size < 2097152) {
      props.setError(false);
      onFileUpload(files);
    }
  };

  const onSubmit = async () => {
    if (inputFile && !!inputFile.length) {
      const cropped = await getCroppedImg(imageSelected, croppedAreaPixels);
      const file = inputFile[0];
      const newFile = await base64ToFile(cropped, file.name, file.type);
      props.onSubmit(newFile);
      // setCroppedImage(cropped);
      // props.onChangeFace();
    }
  };

  const onSelectUploadButton = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click();
    }
  };

  useEffect(() => {
    if (props.assetPath !== "") {
      setImageSelected(props.assetPath);
    }
  }, [props.assetPath]);

  return (
    <CropContainerPlay>
      <CloseButton onClick={props.onCloseImageCropper} />
      <CropTitle>Upload your image</CropTitle>
      <SubtitleContainer>
        <Subtitle>Position and size your image</Subtitle>
        <UploadButton onClick={onSelectUploadButton}>Upload new</UploadButton>
        <input
          ref={hiddenInputRef}
          data-testid="fileInput"
          type="file"
          accept={ACCEPT_TYPES.images}
          hidden
          onChange={(event: any) => onHandleInputChange(event)}
        />
      </SubtitleContainer>
      <CropContainer>
        <Cropper
          image={imageSelected}
          crop={crop}
          zoom={zoom}
          aspect={218 / 296}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </CropContainer>
      <ZoomContainer>
        <IconWrapper>
          <IconImage
            image={`${PUBLIC_URL}/edit-image-modal-img.svg`}
            size="28px"
          />
        </IconWrapper>
        <CropperSlider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e: any, zoom2: any) => setZoom(Number(zoom2))}
          classes={{ root: "slider" }}
        />
        <IconWrapper>
          <IconImage
            image={`${PUBLIC_URL}/edit-image-modal-img.svg`}
            size="40px"
          />
        </IconWrapper>
      </ZoomContainer>
      <ButtonContainer>
        {inputFile && !props.hasError && (
          <ButtonApply onClick={onSubmit}>Apply</ButtonApply>
        )}
      </ButtonContainer>
    </CropContainerPlay>
  );
};

export default ImageCropper;
