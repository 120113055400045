import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";
import { SIZES } from "common/constants/sizes.contants";
import { PUBLIC_URL } from "configs/configs";
import { ReactComponent as backButton } from "assets/avatarNearpodCampaign/arrow_continue-01.svg";
import { ReactComponent as cap } from "../assets/title_asset_cap.svg";
import { ReactComponent as short } from "../assets/title_asset_short.svg";
import { ReactComponent as skin } from "../assets/title_asset_skin.svg";
import { ReactComponent as tshirt } from "../assets/title_asset_tshirt.svg";
import { Button } from "../Step1/styles";
import { IContinueButton, IStateSelected } from "./types";

export const StrictContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ebac75;
  position: relative;
  overflow: hidden;
`;

export const GreatContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80%;
    height: 98%;
    justify-content: flex-end;
  }
`;

export const UpperSection = styled.div`
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    height: 50%;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 280px;
  }
`;

export const Container = styled.div<IStateSelected>`
  width: 10vh;
  height: 10vh;
  cursor: pointer;
  margin: 15px;
  border-radius: 10px;
  ${(props) => (props.isSelected ? "background-color: white" : "")};

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 9vh;
    height: 9vh;
  }
`;

export const Bodies = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Body = styled.div<IStateSelected>`
  width: 10vh;
  height: 10vh;
  cursor: pointer;
  margin: 15px;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  ${(props) =>
    props.isSelected ? `box-shadow: 0 0 0 6px ${COLORS.redSalsa};` : ""}
  ${(props) => (props.isSelected ? "transition: all 0.3s linear;" : "")};

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 9vh;
    height: 9vh;
  }
`;

export const BodyImage = styled.img<IStateSelected>`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  ${(props) =>
    props.isSelected ? `box-shadow: 0 0 0 4px ${COLORS.redSalsa};` : ""}
  ${(props) => (props.isSelected ? "transition: all 0.3s linear;" : "")}
`;

export const Avatar = styled.div`
  width: 40%;
  height: 100%;
  text-align: center;
`;

export const CreateYourAvatar = styled.div`
  width: 100%;
  height: 70px;
  align-self: center;
  background-image: url(${PUBLIC_URL}/nearpodAvatarCampaign/avatar_create_title.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const AvatarDisplay = styled(Avatar)`
  width: 100%;
  height: 55vh;
  position: relative;
  top: -40px;

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    top: -55px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 90%;
    top: -40px;
  }
`;

export const Wardrobe = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const CapTitle = styled(cap)`
  width: 100%;
  height: 40px;
`;
export const ShortTitle = styled(short)`
  width: 100%;
  height: 40px;
`;
export const TshirtTitle = styled(tshirt)`
  width: 100%;
  height: 40px;
`;
export const ClothesSelection = styled.div`
  width: 100%;
  justify-content: space-around;
  display: flex;
`;

export const ClothesOption = styled(Container)<IStateSelected>`
  background: ${(props) => `#F9D8B2 no-repeat top/cover
  url("${props.URL}");`};
  ${(props) =>
    props.isSelected ? `box-shadow: 0 0 0 6px ${COLORS.redSalsa};` : ""};
  background-color: ${(props) => (props.isSelected ? "white;" : "")};
  ${(props) => (props.isSelected ? "transition: all 0.3s linear;" : "")}
  &:hover {
    background: ${(props) => `${COLORS.white} no-repeat top/cover
    url("${props.URL}");`};
  } ;
`;

export const LowerSection = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: Space-between;

  @media screen and (max-width: ${SIZES.bigTablet}px) {
    height: 70px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 40px;
  }
`;

export const BackButtonWrapper = styled.div`
  width: 10%;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 20%;
  }
`;

export const ColorPicker = styled(ClothesSelection)`
  width: 40%;
  height: fit-content;
  justify-content: center;
  z-index: 10;

  @media screen and (max-width: ${SIZES.bigTablet}px) {
    width: 40%;
  }
`;

export const SkinColor = styled(skin)`
  width: 100px;
  height: 30px;
  margin: 0;
  align-self: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 70px;
  }
`;

export const Color = styled(Container)<IStateSelected>`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 0 10px;
  ${(props) =>
    props.isSelected ? `box-shadow: 0 0 0 6px ${COLORS.redSalsa};` : ""}
  ${(props) => (props.isSelected ? "transition: all 0.3s linear;" : "")}
  background-color: ${(props) => props.color};
`;

export const Buttons = styled(Button)<IContinueButton>`
  width: 180px;
  height: 100%;
  margin: 0 10px;
  font-size: 18px;
  transition: all 0.3s ease-out;
  opacity: ${(props) => props.disable && "0.5"};
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  &:hover {
    background-color: ${(props) =>
      props.disable ? COLORS.redSalsa : COLORS.vivid};
    cursor: ${(props) => props.disable && "default"};
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 40px;
    margin: 0 3px;
    font-size: 10px;
    width: 150px;
  }
`;

export const OtherButtons = styled.div`
  width: 39%;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;

  @media screen and (max-width: ${SIZES.bigTablet}px) {
    width: 40%;
  }
`;

export const ButtonContainer = styled.div`
  width: 190px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  &:hover {
    box-shadow: 0 0 0 3px ${COLORS.redSalsa};
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 150px;
  }
`;

export const BackButton = styled(backButton)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin: 0 20px;
  transform: rotate(180deg);
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 20px;
    height: 20px;
    margin: 0 15px;
  }
`;

export const BackButtonContainer = styled.div`
  width: 130px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  &:hover {
    box-shadow: 0 0 0 3px ${COLORS.redSalsa};
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80px;
  }
`;
export const LottieContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 33px;
  left: 0;
`;

export const BackText = styled.p`
  font-size: 18px;
  font-family: Avenir-Medium;
  color: ${COLORS.fiord};
  margin-left: -8px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 10px;
  }
`;

export const ContinueBackText = styled(BackText)`
  margin-right: 10px;
`;

export const BackButtonReverse = styled(backButton)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 20px;
    height: 20px;
  }
`;

export const ContinueButton = styled(ButtonContainer)<IContinueButton>`
  opacity: ${(props) => props.disable && "0.5"};
  cursor: ${(props) => (!props.disable ? "pointer" : "default")};
  justify-content: center;
  margin-right: 10px;
  &:hover {
    box-shadow: ${(props) =>
      props.disable
        ? "0 9px 9px rgb(0 0 0 / 16%)"
        : `0 0 0 3px ${COLORS.redSalsa}`};
    cursor: ${(props) => props.disable && "default"};
  }
  ${BackButtonReverse} {
    cursor: ${(props) => (!props.disable ? "pointer" : "default")};
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin: 0;
  }
`;

export const JetiContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
`;
