export const ON_UPLOAD_SUCCESS = "ON_UPLOAD_SUCCESS";
export const ON_UPLOAD_FAILED = "ON_UPLOAD_FAILED";
export const ON_UPLOAD_REQUESTED = "ON_UPLOAD_REQUESTED";
export const ON_RETRIEVE_ASSET_PATH_REQUESTED =
  "ON_RETRIEVE_ASSET_PATH_REQUESTED";
export const ON_RETRIEVE_ASSET_PATH_SUCCESS = "ON_RETRIEVE_ASSET_PATH_SUCCESS";
export const ON_RETRIEVE_ASSET_PATH_FAILED = "ON_RETRIEVE_ASSET_PATH_FAILED";
export const ON_INITIALIZE_ASSETS_DASHBOARD_REQUESTED =
  "ON_INITIALIZE_ASSETS_DASHBOARD_REQUESTED";
export const ON_RETRIEVE_ASSETS_SUCCEED = "ON_RETRIEVE_ASSETS_SUCCEED";
export const ON_RETRIEVE_ASSETS_FAILED = "ON_RETRIEVE_ASSETS_FAILED";
export const ON_RETRIEVE_ASSET_COUNT_SUCCEED =
  "ON_RETRIEVE_ASSET_COUNT_SUCCEED";
export const ON_RETRIEVE_ASSET_COUNT_FAILED = "ON_RETRIEVE_ASSET_COUNT_FAILED";
export const ON_SELECT_ADD_ASSET = "ON_SELECT_ADD_ASSET";
export const ON_SUBMIT_ASSET_REQUESTED = "ON_SUBMIT_ASSET_REQUESTED";
export const ON_SUBMIT_ASSET_SUCCEED = "ON_SUBMIT_ASSET_SUCCEED";
export const ON_SUBMIT_ASSET_FAILED = "ON_SUBMIT_ASSET_FAILED";
export const ON_DELETE_ASSET_REQUESTED = "ON_DELETE_ASSET_REQUESTED";
export const ON_DELETE_ASSET_SUCCEED = "ON_DELETE_ASSET_SUCCEED";
export const ON_DELETE_ASSET_FAILED = "ON_DELETE_ASSET_FAILED";
export const ON_SUBMIT_CROP_ASSET_SUCCEED = "ON_SUBMIT_CROP_ASSET_SUCCEED";
export const ON_SUBMIT_CROP_ASSET_REQUESTED = "ON_SUBMIT_CROP_ASSET_REQUESTED";
export const ON_EXPORT_REQUESTED = "ON_EXPORT_REQUESTED";
export const ON_EXPORT_SUCCEED = "ON_EXPORT_SUCCEED";
