import { COLORS } from "common/constants/color.contants";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import React, { FunctionComponent } from "react";
import { useMobileOrientation, isMobile } from "react-device-detect";
import styled from "styled-components";
import Step1 from "./components/Step1/Step1";
import Step2 from "./components/Step2/Step2";
import Step3 from "./components/Step3/Step3";
import Step4 from "./components/Step4/Step4";
import RotattionAnim from "./rotate.json";
import { INearpodCampEngageCharacterCampaignViewProps } from "./types";

const ViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.black};
}`;

const NearpodCampEngageCharacterCampaignView: FunctionComponent<INearpodCampEngageCharacterCampaignViewProps> =
  (props: INearpodCampEngageCharacterCampaignViewProps) => {
    const { isLandscape } = useMobileOrientation();
    return (
      <ViewContainer>
        {!isLandscape && isMobile ? (
          <LottiePlayer
            autoplay
            loop
            animationJsonPath={RotattionAnim}
            preserveAspectRatio="xMidYMid meet"
          />
        ) : (
          <div>
            {props.step === 1 && <Step1 onSubmitForm={props.onSubmitForm} />}
            {props.step === 2 && (
              <Step2
                onConfirmCharacter={props.onConfirmCharacter}
                faceAsset={props.faceAsset}
                addAsset={props.addAsset}
                backToForm={props.backToForm}
                data={props.data}
                showYeti={props.showYeti}
                isLoadingImage={props.isLoadingImage}
              />
            )}
            {props.step === 3 && (
              <Step3
                faceImage={props.faceAsset}
                backToSelectCharacter={props.backToSelectCharacter}
                data={props.data}
                uploadExport={props.uploadExport}
                onCreateAndExport={props.onCreateAndExport}
                isLoading={props.isLoadingImage}
              />
            )}
            {props.step === 4 && (
              <Step4
                avatarImage={props.data.avatarAssetPath}
                badgeImage={props.data.badgeAssetPath}
              />
            )}
          </div>
        )}
      </ViewContainer>
    );
  };

export default NearpodCampEngageCharacterCampaignView;
