import { AnyAction } from "redux";
import * as shareConstants from "redux/constants/share.constants";
import * as Sentry from "@sentry/react";

const loggingMiddleware =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) =>
  (action: AnyAction): AnyAction => {
    switch (action.type) {
      case shareConstants.ON_INITIALIZE_PLAYER_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `Share Player Init with id: ${action.shareId}`
          )
        );
        break;
      default:
        break;
    }
    return next(action);
  };

export default loggingMiddleware;
