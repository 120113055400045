import { AxiosResponse } from "axios";
import { get, put } from "./api";

export const getTenAssetsService = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`assets/get/${page}/`);
  } else {
    response = await get(`assets/get/${page}/${query}`);
  }
  return response.data;
};

export const getAssetImagePath = async (id: string): Promise<string> => {
  const response = await get(`assets/${id}`);
  return response.data as string;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`assets/get-count`);
  return response.data;
};

export const deleteAssets = async (
  assetsIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    assetsIds,
  };
  const response = await put(`assets/delete/`, data);
  return response.data;
};
