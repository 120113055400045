/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chart, registerables } from "chart.js";
import React, { FunctionComponent, useEffect } from "react";
import i18next from "i18next";
import "./i18n";

import {
  CampaignContainer,
  Container,
  ImageContainer,
  TextsContainer,
  Title,
  TopColor,
  Image,
  CampaignTitle,
  QuickStatsTitle,
  StatsContainer,
  Stat,
  StatName,
  StatNumber,
  InfoContainer,
  Text,
  Wrapper,
  StatsSectionContainer,
  ChartsContainer,
  ChartContainer,
  config,
  ChartContainerPie,
  VideoWatched,
  TextSend,
  BackgroundWrapper,
  ChartWrapper,
} from "./styles";
import { IProps } from "./types";

const StatsCampaign: FunctionComponent<IProps> = (props: IProps) => {
  Chart.register(...registerables);
  const data = {
    labels: ["0% - 25%", "25% - 50%", "50% - 75%", "75% +"],
    datasets: [
      {
        label: "customers",
        data: [
          props.metrics.porcentageViews.upToZero,
          props.metrics.porcentageViews.upToTwentyFive,
          props.metrics.porcentageViews.upToFifty,
          props.metrics.porcentageViews.upToSeventyFive,
        ],
        backgroundColor: ["rgba(33,	69,	105, 0.2)"],
        borderColor: ["rgba(33, 9, 105, 1)"],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const dataPie = {
    labels: ["Play Count", "asd"],
    datasets: [
      {
        label: "votes",
        data: [4, 7],
        backgroundColor: ["rgba(233, 133, 115, 1)", "rgba(33,	69,	105, 1)"],
        borderColor: ["rgba(33, 9, 105, 1)"],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };
  const ctx = "myChart";
  const ctxPie = "myChartPie";
  let myChart: Chart<"bar", number[], string>;
  let myChartPie: Chart<"pie", number[], string>;
  useEffect(() => {
    myChart = new Chart(ctx, {
      type: "bar",
      data,
      options: config,
    });
    myChartPie = new Chart(ctxPie, {
      type: "pie",
      data: dataPie,
    });
  }, []);

  return (
    <BackgroundWrapper>
      <Wrapper>
        <Container>
          <TopColor />
          <CampaignContainer>
            <TextsContainer>
              <Title>{i18next.t("STATS_VIEW:TITLE")}</Title>
              <CampaignTitle>{props.metrics.campaignName}</CampaignTitle>
              <InfoContainer>
                <Text>
                  {i18next.t("STATS_VIEW:DURATION")} {props.metrics.duration}
                </Text>
                <TextSend>{i18next.t("STATS_VIEW:SEND")} </TextSend>
              </InfoContainer>
            </TextsContainer>
            <ImageContainer>
              <Image src="https://w7.pngwing.com/pngs/208/269/png-transparent-youtube-play-button-computer-icons-youtube-youtube-logo-angle-rectangle-logo.png" />
            </ImageContainer>
          </CampaignContainer>
          <StatsSectionContainer>
            <QuickStatsTitle>{i18next.t("STATS_VIEW:QUICK")}</QuickStatsTitle>
            <StatsContainer>
              <Stat>
                <StatNumber>{props.metrics.viewsCount}</StatNumber>
                <StatName>{i18next.t("STATS_VIEW:VIEW_COUNT")}</StatName>
              </Stat>
              <Stat>
                <StatNumber>
                  {props.metrics.averageWatchedTimePercentage}
                </StatNumber>
                <StatName>{i18next.t("STATS_VIEW:AVERAGE_VIEW")}</StatName>
              </Stat>
              <Stat>
                <StatNumber>{props.metrics.clickedCTACount}</StatNumber>
                <StatName>{i18next.t("STATS_VIEW:CTA_CLICKS")}</StatName>
              </Stat>
            </StatsContainer>
          </StatsSectionContainer>
          <ChartsContainer>
            <ChartWrapper>
              <VideoWatched>
                {i18next.t("STATS_VIEW:PERCENTAGE_VIDEO")}
              </VideoWatched>
              <ChartContainer>
                <canvas id="myChart" width="400px" height="200px" />
              </ChartContainer>
            </ChartWrapper>
            <ChartWrapper>
              <VideoWatched>{i18next.t("STATS_VIEW:ALL_USERS")}</VideoWatched>
              <ChartContainerPie>
                <canvas id="myChartPie" width="50px" height="50px" />
              </ChartContainerPie>
            </ChartWrapper>
          </ChartsContainer>
        </Container>
      </Wrapper>
    </BackgroundWrapper>
  );
};
export default StatsCampaign;
