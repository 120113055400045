import { IAPIError } from "common/interfaces/api.interface";
import { ICampaign } from "common/interfaces/campaign.interface";
import { ITemplate } from "common/interfaces/template.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/campaign.constants";

export const onInitializeCampaignForm = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CAMPAIGN_FORM,
});

export const onGetTemplatesSucceed = (data: ITemplate[]): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATES_SUCCEED,
  data,
});

export const onGetTemplatesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_TEMPLATES_FAILED,
  error,
});

export const onCreateCampaign = (
  templateId: string,
  clientId: string,
  campaignName: string,
  metadataTitle: string,
  metadataDescription: string,
  metadataImage: File
): AnyAction => ({
  type: constants.ON_CREATE_CAMPAIGN_REQUESTED,
  templateId,
  clientId,
  campaignName,
  metadataTitle,
  metadataDescription,
  metadataImage,
});

export const onCreateCampaignSucceed = (campaign: ICampaign): AnyAction => ({
  type: constants.ON_CREATE_CAMPAIGN_SUCCEED,
  campaign,
});

export const onCreateCampaignFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_CREATE_CAMPAIGN_FAILED,
  error,
});

export const getCampaigns = (query: string, page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGNS_REQUESTED,
  query,
  page,
});

export const getCampaignsSucceed = (data: ICampaign[]): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGNS_SUCCEED,
  data,
});

export const getCampaignsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGNS_FAILED,
  error,
});

export const selectCampaignSucced = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_CAMPAIGN_SUCCEED,
  campaignId,
});

export const onRedirectToMetrics = (campaignId: string): AnyAction => ({
  type: constants.ON_REDIRECT_TO_METRICS,
  campaignId,
});

export const deleteCampaigns = (campaignsId: string[]): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGNS_REQUESTED,
  campaignsId,
});

export const deleteCampaignsSucceed = (data: ICampaign[]): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGNS_SUCCEED,
  data,
});

export const deleteCampaignsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_CAMPAIGNS_FAILED,
  error,
});

export const onSelectCampaignAdd = (): AnyAction => ({
  type: constants.ON_SELECT_CAMPAIGN_ADD,
});

export const onCreateShareCampaigns = (campaignId: string): AnyAction => ({
  type: constants.ON_CREATE_SHARE_CAMPAIGN_REQUESTED,
  campaignId,
});

export const getCampaignCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGN_COUNT_SUCCEED,
  count,
});

export const getCampaignCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGN_COUNT_FAILED,
  error,
});

export const getCampaignCount = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CAMPAIGN_REQUESTED,
  count,
});
