import { ITemplate, Template } from "common/interfaces/template.interface";
import { AnyAction } from "redux";
import { ON_RETRIEVE_TEMPLATES_SUCCEED } from "redux/constants/campaign.constants";
import {
  ON_INITIALIZE_ADD_VIEW_SUCCEED,
  ON_INITIALIZE_MATCH_CSV_SUCCEED,
  ON_INITIALIZE_PLAYER_SUCCEED,
} from "redux/constants/share.constants";
import {
  ON_CREATE_TEMPLATE_REQUESTED,
  ON_CREATE_TEMPLATE_SUCCESS,
  ON_DELETE_TEMPLATES_SUCCESS,
  ON_INITIALIZE_TEMPLATE_FORM,
  ON_INITIALIZE_TEMPLATE_SUCCEED,
  ON_RETRIEVE_TEMPLATE_COUNT_SUCCEED,
} from "redux/constants/template.constants";

export interface ITemplateReducerState {
  templates: Template[];
  count: number;
  template: ITemplate;
  loading: boolean;
}

const defaultState: ITemplateReducerState = {
  templates: [],
  count: 0,
  template: {
    template: {
      _id: "",
      title: "",
      description: "",
      duration: 0,
      backgroundColor: "",
      createdAt: "",
      updatedAt: "",
    },
    title: "",
    textVariables: [],
    imageVariables: [],
    layers: [],
    audio: {
      _id: "",
      name: "",
      assetId: "",
      templateId: "",
      createdAt: "",
      dataPath: "",
      updatedAt: "",
    },
    video: {
      _id: "",
      assetId: "",
      templateId: "",
      createdAt: "",
      dataPath: "",
      updatedAt: "",
    },
  },
  loading: false,
};

const addTemplate = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    template: action.template,
    loading: false,
  };
};

const addShareTemplate = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    template: action.data.template,
  };
};

const addTemplates = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    templates: action.data,
  };
};

const addTemplateCount = (state: ITemplateReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const setLoading = (state: ITemplateReducerState) => {
  return {
    ...state,
    loading: true,
  };
};

const removeTemplateData = (state: ITemplateReducerState) => {
  return {
    ...state,
    template: defaultState.template,
  };
};

const templateReducer = (
  state: ITemplateReducerState = defaultState,
  action: AnyAction
): ITemplateReducerState => {
  switch (action.type) {
    case ON_CREATE_TEMPLATE_REQUESTED:
      return setLoading(state);
    case ON_INITIALIZE_TEMPLATE_SUCCEED:
    case ON_CREATE_TEMPLATE_SUCCESS:
    case ON_INITIALIZE_MATCH_CSV_SUCCEED:
    case ON_INITIALIZE_ADD_VIEW_SUCCEED:
      return addTemplate(state, action);
    case ON_INITIALIZE_PLAYER_SUCCEED:
      return addShareTemplate(state, action);
    case ON_RETRIEVE_TEMPLATES_SUCCEED:
    case ON_DELETE_TEMPLATES_SUCCESS:
      return addTemplates(state, action);
    case ON_INITIALIZE_TEMPLATE_FORM:
      return removeTemplateData(state);
    case ON_RETRIEVE_TEMPLATE_COUNT_SUCCEED:
      return addTemplateCount(state, action);
    default:
      return state;
  }
};

export default templateReducer;
