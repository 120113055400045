import { IMetricData } from "common/interfaces/metric.interface";
import { AnyAction } from "redux";
import * as constants from "redux/constants/metric.constants";

export function onInitializeMetricDashboard(campaignId: string): AnyAction {
  return {
    type: constants.ON_INITIALIZE_METRICS_DASHBOARD_REQUESTED,
    campaignId,
  };
}

export function onInitializeMetricDashboardSucceed(
  data: IMetricData
): AnyAction {
  return {
    type: constants.ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED,
    data,
  };
}

export function onInitializeMetricDashboardFailed(): AnyAction {
  return {
    type: constants.ON_INITIALIZE_METRICS_DASHBOARD_FAILED,
  };
}

export function onCreateMetric(shareCampaignId: string): AnyAction {
  return {
    type: constants.ON_CREATE_METRIC_REQUESTED,
    shareCampaignId,
  };
}

export function onCreateMetricSucceed(id: string): AnyAction {
  return {
    type: constants.ON_CREATE_METRIC_SUCCEED,
    id,
  };
}

export function onCreateMetricFailed(): AnyAction {
  return {
    type: constants.ON_CREATE_METRIC_FAILED,
  };
}

export function onUpdateWatchedTime(lastSecondPlayed: number): AnyAction {
  return {
    type: constants.ON_UPDATE_WATCHED_TIME_REQUESTED,
    lastSecondPlayed,
  };
}

export function onUpdateWatchedTimeSucceed(): AnyAction {
  return {
    type: constants.ON_UPDATE_WATCHED_TIME_SUCCEED,
  };
}

export function onUpdateWatchedTimeFailed(): AnyAction {
  return {
    type: constants.ON_UPDATE_WATCHED_TIME_FAILED,
  };
}

export function onUpdateAudioClicked(): AnyAction {
  return {
    type: constants.ON_UPDATE_AUDIO_CLICKED_REQUESTED,
  };
}

export function onUpdateAudioClickedSucceed(): AnyAction {
  return {
    type: constants.ON_UPDATE_AUDIO_CLICKED_SUCCEED,
  };
}

export function onUpdateAudioClickedFailed(): AnyAction {
  return {
    type: constants.ON_UPDATE_AUDIO_CLICKED_FAILED,
  };
}

export function onUpdateCTAClicked(): AnyAction {
  return {
    type: constants.ON_UPDATE_CTA_CLICKED_REQUESTED,
  };
}

export function onUpdateCTAClickedSucceed(): AnyAction {
  return {
    type: constants.ON_UPDATE_CTA_CLICKED_SUCCEED,
  };
}

export function onUpdateCTAClickedFailed(): AnyAction {
  return {
    type: constants.ON_UPDATE_CTA_CLICKED_FAILED,
  };
}
