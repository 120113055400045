import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";
import { ReactComponent as backButton } from "assets/avatarNearpodCampaign/arrow_continue-01.svg";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f29150;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
`;

export const BadgeButtonWrapper = styled.div`
  position: absolute;
  top: 740px;
  left: 160px;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 670px;
  }
`;

export const AvatarButtonWrapper = styled.div`
  position: absolute;
  top: 740px;
  left: 785px;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 670px;
    left: 790px;
  }
`;

export const AvatarWrapper = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  border: 5px solid ${COLORS.white};
  border-radius: 100%;
  background-color: ${COLORS.white};
  position: absolute;
  z-index: 10;
  left: 680px;
  top: 300px;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 340px;
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 250px;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 300px;
    height: 300px;
    top: 270px;
    left: 640px;
    right: 0;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 200px;
    height: 200px;
    top: 110px;
    left: 500px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 410px;
  }
`;

export const BackButtonContainer = styled.div`
  width: 130px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  &:hover {
    box-shadow: 0 0 0 3px ${COLORS.redSalsa};
  }
  position: absolute;
  top: 780px;
  right: 1020px;
  z-index: 10;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    right: 970px;
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 690px;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 595px;
    left: 35px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    top: 320px;
    left: 100px;
    width: 80px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 30px;
  }
`;

export const BackButton = styled(backButton)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin: 0 20px;
  transform: rotate(180deg);
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
`;

export const BackText = styled.p`
  font-size: 18px;
  font-family: Avenir-Medium;
  color: ${COLORS.fiord};

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 14px;
  }
`;

export const BadgeWrapper = styled.div`
  width: 700px;
  height: 400px;
  position: absolute;
  top: 300px;
  right: 400px;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 340px;
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 250px;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 525px;
    height: 300px;
    top: 270px;
    left: 60px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 350px;
    height: 200px;
    top: 110px;
    left: 100px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 30px;
  }
`;

export const Button = styled.button`
  background-color: ${COLORS.redSalsa};
  color: ${COLORS.white};
  font-family: Avenir-Medium;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  align-self: center;
  cursor: pointer;
  width: 180px;
  margin: 0 10px;
  display: flex;
  transition: all 0.3s ease-out;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  position: absolute;
  z-index: 100;
  top: 30px;
  &:hover {
    background-color: ${COLORS.vivid};
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    margin: 0;
    top: 770px;
    left: 450px;
    width: auto;
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    width: 180px;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    left: 430px;
    top: 600px;
    width: auto;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    top: 320px;
    left: 355px;
    font-size: 14px;
    width: auto;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 270px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  top: 50px;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  width: 500px;
  left: 240px;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 160px;
    width: 500px;
    left: 280px;
  }

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: 768px) and (orientation: landscape) {
    top: 30px;
    width: 500px;
    left: 280px;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 130px;
    width: 350px;
    left: 320px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    top: 15px;
    width: 200px;
    left: 310px;
  }

  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 235px;
  }
`;

export const ImageTitle = styled.img`
  position: relative;
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: "Avenir-Black";
  color: ${COLORS.white};
  font-size: 36px;
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 28px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 16px;
  }
`;
