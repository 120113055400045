import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f29150;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
`;

export const YetiContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
`;

export const BadgeImage = styled.img`
  z-index: 2;
  @media screen and (max-width: ${SIZES.bigDesktop}px) and (orientation: landscape) {
    width: 700px;
    height: 400px;
    margin-top: 100px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 525px;
    height: 300px;
    margin-top: 60px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 350px;
    height: 200px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    margin-top: 80px;
  }
`;

export const AvatarImage = styled.img`
  border-radius: 100%;
  z-index: 2;
  @media screen and (max-width: ${SIZES.bigDesktop}px) and (orientation: landscape) {
    width: 400px;
    height: 400px;
    margin-top: 100px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 300px;
    height: 300px;
    margin-top: 60px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 200px;
    height: 200px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    margin-top: 80px;
  }
`;

export const BadgeSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  @media screen and (max-width: ${SIZES.bigDesktop}px) and (orientation: landscape) {
    left: 60px;
    top: 40px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    left: 60px;
    top: 40px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    left: 95px;
    top: 25px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 40px;
    top: 20px;
  }
`;

export const AvatarSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  @media screen and (max-width: ${SIZES.bigDesktop}px) and (orientation: landscape) {
    right: 60px;
    top: 40px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    right: 60px;
    top: 40px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    right: 95px;
    top: 25px;
  }
  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    right: 40px;
    top: 20px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  top: 50px;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  width: 500px;
  left: 240px;

  @media screen and (max-width: ${SIZES.bigDesktop}px) and (orientation: landscape) {
    top: 160px;
    width: 500px;
    left: 435px;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    top: 130px;
    width: 350px;
    left: 320px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    top: 20px;
    width: 210px;
    left: 305px;
  }

  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    left: 235px;
  }
`;

export const ImageTitle = styled.img`
  position: relative;
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: "Avenir-Black";
  color: ${COLORS.white};
  font-size: 36px;
  text-align: center;
  @media screen and (max-width: ${SIZES.bigDesktop}px) and (max-height: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 30px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 30px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 20px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 16px;
  }
`;
