import { IDataLayer } from "common/interfaces/layer.interface";
import { IText } from "common/interfaces/text-variable.interface";
import { IObject } from "helper/types";

const retrieveTexts = (dataLayer: IDataLayer): IText[] => {
  const dataCopy = { ...dataLayer };
  const textVariables: IText[] = [];
  if (!dataCopy.layers) {
    return [];
  }
  dataCopy.assets.forEach((dataAsset: IObject) => {
    const dataAssetCopy = { ...dataAsset };
    if (dataAssetCopy && dataAssetCopy.layers) {
      (dataAssetCopy.layers as IObject[])
        .reverse()
        .forEach((dataAssetLayer: IObject) => {
          if (
            dataAssetLayer.t &&
            ((dataAssetLayer as IObject).t as IObject).d &&
            (((dataAssetLayer as IObject).t as IObject).d as IObject).k
          ) {
            if (
              (
                (
                  (
                    (
                      (((dataAssetLayer as IObject).t as IObject).d as IObject)
                        .k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t as string
              )
                .toUpperCase()
                .includes("#") ||
              (
                (
                  (
                    (
                      (((dataAssetLayer as IObject).t as IObject).d as IObject)
                        .k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t as string
              )
                .toUpperCase()
                .includes("[") ||
              (
                (
                  (
                    (
                      (((dataAssetLayer as IObject).t as IObject).d as IObject)
                        .k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t as string
              )
                .toUpperCase()
                .includes("]")
            ) {
              textVariables.push({
                name: (dataAssetLayer as IObject).nm as string,
                value: (
                  (
                    (
                      (((dataAssetLayer as IObject).t as IObject).d as IObject)
                        .k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t as string,
                type: 0,
              });
            } else {
              textVariables.push({
                name: (dataAssetLayer as IObject).nm as string,
                value: (
                  (
                    (
                      (((dataAssetLayer as IObject).t as IObject).d as IObject)
                        .k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t as string,
                type: 1,
              });
            }
          }
        });
    }
  });
  textVariables.sort((a: IText, b: IText) => a.name.localeCompare(b.name));
  textVariables[textVariables.length - 1].type = 2;
  return textVariables;
};

export default retrieveTexts;
