import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "STATS_VIEW", {
  TITLE: "Bodymovin Solutions Campaign",
  DURATION: "Duration:",
  SEND: "Send:",
  QUICK: "Quick stats",
  VIEW_COUNT: "VIEW COUNT",
  AVERAGE_VIEW: "AVERAGE VIEW DURATION (%)",
  CTA_CLICKS: "CTA CLICKS",
  PERCENTAGE_VIDEO: "% OF VIDEO WATCHED",
  ALL_USERS: "ALL USERS",
});
