import { ITemplateReducerState } from "redux/reducers/template.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getTemplate = (state: IStore) => state.template;

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: ITemplateReducerState) => number
> => createSelector([getTemplate], (data) => data.count);

const getTemplateSelector = (): OutputSelector<
  IStore,
  ITemplateReducerState,
  (res: ITemplateReducerState) => ITemplateReducerState
> => createSelector([getTemplate], (template) => template);

export default getTemplateSelector;
