import { IMetricReducerState } from "common/interfaces/metric.interface";
import { AnyAction } from "redux";
import {
  ON_CREATE_METRIC_SUCCEED,
  ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED,
} from "redux/constants/metric.constants";

const defaultState: IMetricReducerState = {
  data: {
    totalWatchTime: "",
    averageWatchedTime: "",
    viewsCount: 0,
    soundPlayedCount: 0,
    finishedVideoCount: 0,
    finishedVideoPercentage: 0,
    clickedCTACount: 0,
    duration: 0,
    uniqueViewers: 0,
    upToSeventyFivePercent: 0,
    averageWatchedTimePercentage: 0,
    campaignName: "",
    porcentageViews: {
      upToSeventyFive: 0,
      upToFifty: 0,
      upToTwentyFive: 0,
      upToZero: 0,
    },
    campaignId: "",
    templateId: "",
    clientId: "",
    createdAt: "",
  },
  id: "",
};

const addMetricsData = (state: IMetricReducerState, action: AnyAction) => {
  return {
    ...state,
    data: action.data,
  };
};

const addMetricId = (state: IMetricReducerState, action: AnyAction) => {
  return {
    ...state,
    id: action.id,
  };
};

const metricReducer = (
  state: IMetricReducerState = defaultState,
  action: AnyAction
): IMetricReducerState => {
  switch (action.type) {
    case ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED:
      return addMetricsData(state, action);
    case ON_CREATE_METRIC_SUCCEED:
      return addMetricId(state, action);
    default:
      return state;
  }
};

export default metricReducer;
